import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from 'app/account/account.service';
import { NotificationService } from 'app/_services/notification.service';
import { SellerService } from 'app/seller/seller.service';
import { SellerDocumentacaoService } from 'app/seller/documentacao/documentacao.service';
import { SellerResponderComponentService } from '../responder.component.service';
import { SettingsService } from 'app/_services/settings.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { Peca } from 'app/seller/responder/_models/peca';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { User } from 'app/_models/user';

declare const $: any;
declare const isEmpty: any;

@Component({ selector: 'app-responder-maior1024', templateUrl: './maior1024.component.html', styleUrls: ['./maior1024.component.scss'] })
export class SellerVisaoGeralResponderMaior1024Component implements OnInit {
    @ViewChild('templateConfiguracaoFormaPagamento') templateConfiguracaoFormaPagamento: TemplateRef<any>;
    modalFPRef: BsModalRef;

    get f() { return this.responderCompService.responderForm.controls; }

    area_faturamento: false;
    loading_limite_credito: boolean;
    novo_limite: number;
    usuarioLogado: User;

    // tslint:disable-next-line: max-line-length
    constructor(
        public accountService: AccountService,
        private notificationService: NotificationService,
        private documentationService: SellerDocumentacaoService,
        public responderCompService: SellerResponderComponentService,
        public sellerService: SellerService,
        private settingsService: SettingsService,
        private globals: Globals,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private carouselConfig: NgbCarouselConfig)
    // tslint:disable-next-line: one-line
    {
        this.carouselConfig.interval = 200000;
    }

    ngOnInit() {
        var param_id = this.route.snapshot.params.id;
        this.responderCompService.onInit(param_id);
        this.usuarioLogado = this.sellerService.usuarioLogado;
    }

    ngAfterViewChecked()
    {
        // Caso a tela esteja com erro, volta para a home
        if (this.responderCompService.isOnError)
            this.responderCompService.voltar({ corpo: 'seller.dashboard.mensagens.listar.erro.sem-codigo', parametro: '' });
    }

    copiarClipboard(peca) {
        let texto = isEmpty(peca.new_part_code) ? peca.code : peca.new_part_code;

        if (isEmpty(texto))
            return;

        texto = texto.trim();

        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (texto));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });

        document.execCommand('copy');
        this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.responder.mensagens.sucesso.copiar-codigo.corpo'));
    }

    modal_condicao_fornecimento_abrir() {
        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalFPRef = this.modalService.show(this.templateConfiguracaoFormaPagamento, options);
    }

    modal_condicao_fornecimento_fechar() {
        this.modalFPRef.hide();
    }

    obterTotalComFrete() {
        let total = 0;
        if (this.responderCompService.answerQuotation != undefined) {
            total = this.sellerService.obterTotalNumerico(this.responderCompService.answerQuotation);

            if (this.responderCompService.answerQuotation.frete)
                total += !this.responderCompService.answerQuotation.frete.valor || this.responderCompService.answerQuotation.frete.valor.toString() === '' || this.responderCompService.answerQuotation.frete.valor <= 0 ? 0 : this.responderCompService.answerQuotation.frete.valor;
            else
                total += this.responderCompService.answerQuotation.faturamento_valor;
        }

        return '';
        // return $filter('currency')(total, $filter('translate')("outros.currency") + " ");
    }

    saveBuyersLimit(nAttempts: number) {
        this.loading_limite_credito = true;

        var subscriptionStatus = 2;
        var limit = this.novo_limite;
        var buyerCompanyId = this.responderCompService.answerQuotation.buyer_company_id;

        this.documentationService.updateBuyersLimit(buyerCompanyId, subscriptionStatus, limit).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    this.responderCompService.answerQuotation.subscription_status = 2;
                    this.notificationService.showSuccessToastr(this.globals.translate('seller.dashboard.responder.mensagens.sucesso.limite-credito.corpo'));
                }

                this.loading_limite_credito = false;
            },
            error: error =>
            {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (error.status === 400 || nAttempts >= 5)
                {
                    this.loading_limite_credito = false;
                    this.notificationService.showErrorToastr(this.globals.translate('seller.dashboard.responder.mensagens.erro.limite-credito.corpo'));
                    return;
                }

                this.saveBuyersLimit(++nAttempts);
            }
        });
    }

    getPartDetails(part: Peca, index: number) {
        let partCodeEmpty = isEmpty(part.code) && isEmpty(part.new_part_code);
        part.block_edit = !part.block_edit;

        if (!part.block_edit && !partCodeEmpty && this.responderCompService.answerQuotation.has_integration) {
            let params: SweetAlertParams = {
                bigSwall: true,
                icon: 'warning',
                message: 'seller.pedidos.responder.buscar-peca.pergunta',
                cancelButtonText: 'elemento.generico-select.nao',
                confirmButtonText: 'elemento.generico-select.sim'
            }

            this.notificationService.showConfirm(params).then((result) => {
                if (result.isDismissed)
                    return;

                this.responderCompService.getPartDetails(part, index);
            });
        }
    }

    whatsApp_abrir() {
        var url = this.settingsService.getWhatsAppWaMeSuporteUrl();
        window.open(url, '_blank');
    }

    validateQuantityInStock(peca: Peca) {
        let quantidadeEmEstoqueMaiorQueSolicitado = peca.quantity_in_stock > peca.quantidade;

        if (isEmpty(peca.quantity_in_stock) || quantidadeEmEstoqueMaiorQueSolicitado)
            peca.quantity_in_stock = 0;
    }
}
